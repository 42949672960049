




import {Component, Vue} from 'vue-property-decorator';
import {loginService} from '@/services/login.service';
import {BizSirenResultDto} from '@/services/LoginModels';

@Component
export default class KakaoCallBack extends Vue {
  private code: string = this.$route.query.code as string;
  private state: string = this.$route.query.state as string;
  private host: string = window.location.protocol + '//' + window.location.host;
  kakaoLoginDto = {
    code: this.code,
    state: this.state,
    redirectUrl: this.host + '/noHeader/p/login/kakaocallback',
  };

  mounted(): void {
    window.opener.recvKakaoResult(this.kakaoLoginDto);
    window.close();
  }
}
